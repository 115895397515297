<template>
    <div class="dots">
        <div class="dot"
             v-for="(n, index) in dot.maximum"
             v-bind:key="index"
             v-on:click="setValue(n)"
             v-bind:class="{'selected' : n <= dot.level, 'minimum' : n <= dot.minimum, 'lock' : lock && lock >= n }">
        </div>
    </div>
</template>


<script>
    export default({
        name: 'dots',
        data() {
            return {
                dot: {
                    level: 0,
                    minimum: 0,
                    maximum: 0,
                    lock: 0
                },
                range: []
            }
        },
        props: [
            'level',
            'minimum',
            'maximum',
            'lock'
        ],
        methods: {
            setValue: function(newValue) {
                if (newValue === this.dot.level) {
                    this.dot.level = Math.max(newValue-1, this.dot.minimum)
                } else {
                    this.dot.level = Math.min(Math.max(newValue, this.dot.minimum), this.dot.maximum);
                }
            }
        },
        watch: {
            dot: {
                handler() {
                    this.$emit('update:level', this.dot.level);
                    this.$emit('update:minimum', this.dot.minimum);
                    this.$emit('update:maximum', this.dot.maximum);
                },
                deep: true
            },
            minimum: function() {
                this.dot.minimum = this.minimum;
                this.dot.level = Math.max(this.dot.level, this.dot.minimum);
            }
        },
        mounted() {
            this.dot.minimum = this.minimum;
            this.dot.maximum = this.maximum;
            if (this.lock) {
                this.dot.lock = this.lock;
            }
            this.setValue(this.level);

        }
    });
</script>

<style lang="scss">
    .dots {
        display: inline-block;
        .dot {
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 2px solid black;
            margin-right: 1px;
            border-radius: 10px;
            &.selected {
                background: red;
            }
            &.lock {
                background: purple;
            }
            &.minimum {
                background: black;
            }
        }
    }
</style>