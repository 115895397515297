<template>
    <div class="third-column">

        <h4>Rituals</h4>
        <span class="add-row" v-on:click="addRitual()">+ Add a ritual</span>
        <div v-for="(ritual,i) in rituals" :key="'ritual-'+i" class="input-wrap specialization-wrap">
            <input type="text" class="label" v-model="ritual[0]" placeholder="Ritual name">
            <dots :level.sync="ritual[1]" :minimum="1" :maximum="5" />
            <span class="close" v-on:click="deleteRow(i)"><i class="fa fa-trash"></i></span>
            <input type="text" class="specialization" v-model="ritual[2]" placeholder="Ritual source" />
        </div>
        <span class="sub">Please provide a source book reference for all rituals including page number.</span>
    </div>
</template>

<script>

    import Dots from './parts/Dots'

    export default({
        name: 'SheetRituals',
        props: [
            'sheetRituals'
        ],
        components: {
            Dots
        },
        data() {
            return {
                rituals: []
            }
        },
        methods: {
            addRitual: function() {
                this.rituals.push(['',1,'']);
            },
            deleteRow(i) {
                this.rituals = this.rituals.filter(function (v, k) {
                    return k !== i;
                });
            }
        },
        watch: {
            rituals: {
                handler: function() {
                    this.$emit('update:sheetRituals', this.rituals);
                },
                deep: true
            }
        },
        mounted() {
            this.rituals = this.sheetRituals;
        }
    });
</script>