<template>
    <div>
        <h2>Attributes</h2>
        <div class="sheet-block">
            <div class="third-column" v-for="(attrBlock, index) in attributes" :key="index">
                <h3>{{ index }}</h3>
                <div v-for="(attr, i) in attrBlock" :key="i" class="input-wrap specialization-wrap">
                    <label :for="i">{{ i }}</label>
                    <dots :level.sync="attr[0]" :minimum="i === 'Appearance' ? 0 : 1" :maximum="5"/>
                    <input type="text" class="specialization" v-model="attr[1]" placeholder="Specialization"/>
                </div>


                <div class="points-summary" v-bind:class="{'available' : Math.max(attributeOrder[index].pointsAvailable - attributeOrder[index].pointsSpent, 0) > 0 }">
                    <div class="order order-primary" v-if="attributeOrder[index].order === 1">Primary</div>
                    <div class="order order-secondary" v-if="attributeOrder[index].order === 2">Secondary</div>
                    <div class="order order-tertiary" v-if="attributeOrder[index].order === 3">Tertiary</div>
                    <div class="points">
                        <span class="label">Points remain:</span>
                        <span class="number">{{ Math.max(attributeOrder[index].pointsAvailable - attributeOrder[index].pointsSpent, 0) }}</span>
                    </div>
                    <div class="freebies" v-show="attributeOrder[index].freebiesSpent > 0">
                        <span class="label">Freebies used:</span>
                        <span class="number">{{ attributeOrder[index].freebiesSpent }}</span>
                    </div>
                    <div class="warning"><i class="fa fa-exclamation-circle"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Dots from './parts/Dots'

    export default {
        name: 'SheetAttributes',
        data() {
            return {
                vampire: true,
                attributes: {}
            }
        },
        computed: {
            attributeOrder: function () {
                let availablePoints = this.vampire ? [7, 5, 3] : [6, 4, 3];
                let keys = Object.keys(this.attributes);
                let obj = {};


                if (keys) {
                    let arr = [];
                    keys.forEach((v) => {
                        arr.push({
                            index: v,
                            order: 0,
                            pointsSpent: this.pointsSpent(v),
                            freebiesSpent: 0
                        });
                    });

                    arr.sort(function(a,b) {
                        return a.pointsSpent <= b.pointsSpent ? 1 : -1;
                    });

                    arr.forEach((v,k) => {
                        arr[k].pointsAvailable = availablePoints[k];
                        arr[k].order = k + 1;
                        arr[k].freebiesSpent = Math.max((arr[k].pointsAvailable - arr[k].pointsSpent) * -5, 0);
                        obj[arr[k].index] = arr[k];
                    });
                }
                return obj;
            }
        },
        components: {
            Dots
        },
        props: ['sheetAttributes', 'sheetVampire', 'sheetFreebies'],
        methods: {
            pointsSpent: function (type) {
                let i = 0;
                let attr = this.attributes[type];

                if (attr) {
                    Object.keys(attr).forEach((v) => {
                        if (attr[v][0] > 0) {
                            i += attr[v][0] - 1;
                        }
                    });
                }
                return i;
            }
        },
        watch: {
            sheetAttributes: {
                handler: function () {
                    this.attributes = this.sheetAttributes;
                },
                deep: true
            },
            sheetVampire: function () {
                this.vampire = this.sheetVampire;
            },
            attributes: {
                deep: true,
                handler: function () {
                    this.$emit('update:sheetAttributes', this.attributes);
                    this.$emit('update:sheetFreebies', [ this.attributeOrder.Physical.freebiesSpent, this.attributeOrder.Social.freebiesSpent, this.attributeOrder.Mental.freebiesSpent ])
                }
            }
        },
        mounted() {
            this.attributes = this.sheetAttributes;
            this.vampire = this.sheetVampire;
        }
    }

</script>