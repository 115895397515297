<template>
    <div class="third-column">
        <h4>Morality</h4>
        <div class="input-wrap path-wrap">
            <input type="text" class="label" v-model="path" placeholder="Humanity/Path">
            <dots :level.sync="pathLevel" :minimum="minimumMorality" :maximum="10" />
        </div>
        <div class="input-wrap">
            <label><strong>Virtues</strong></label>
        </div>
        <div class="input-wrap" v-if="virtues.length > 0">
            <select v-model="virtues[0].name" class="label" v-if="vampire">
                <option value="Conscience">Conscience</option>
                <option value="Conviction">Conviction</option>
            </select>
            <label v-if="!vampire">{{ virtues[0].name }}</label>
            <dots :level.sync="virtues[0].level" :minimum="1" :maximum="5" :lock="virtues[0].lock" />
        </div>
        <div class="input-wrap" v-if="virtues.length > 1">
            <select v-model="virtues[1].name" class="label" v-if="vampire">
                <option value="Self-Control">Self-Control</option>
                <option value="Instinct">Instinct</option>
            </select>
            <label v-if="!vampire">{{ virtues[1].name }}</label>
            <dots :level.sync="virtues[1].level" :minimum="1" :maximum="5" :lock="virtues[1].lock" />
        </div>
        <div class="input-wrap" v-if="virtues.length > 2">
            <label>Courage</label>
            <dots :level.sync="virtues[2].level" :minimum="1" :maximum="5" :lock="virtues[2].lock" />
        </div>

        <div class="points-summary" :class="{'available' : Math.max(7 - virtuesSpent, 0) > 0 }">
            <div class="points">
                <span class="label">Points remain:</span>
                <span class="number">{{ Math.max(7 - virtuesSpent, 0) }}</span>
            </div>
            <div class="freebies" v-show="Math.max(Math.max(virtuesSpent - 7,0) + pathLevel - minimumMorality, 0) > 0">
                <span class="label">Freebies used:</span>
                <span class="number">{{ Math.max(Math.max(virtuesSpent - 7,0) + pathLevel - minimumMorality, 0) * 2 }}</span>
            </div>
            <div class="warning"><i class="fa fa-exclamation-circle"></i></div>
        </div>

        <h4>Willpower</h4>
        <div class="input-wrap willpower-wrap">
            <dots :level.sync="willpower" :minimum="minimumWillpower" :maximum="10" />
        </div>

        <div class="points-summary" v-show="willpower - minimumWillpower > 0">
            <div class="freebies">
                <span class="label">Freebies spent:</span>
                <span class="number">{{ willpower - minimumWillpower }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Dots from './parts/Dots'

    export default({
        name: 'SheetMorality',
        components: {
            Dots
        },
        props: [
            'sheetVirtues',
            'sheetPath',
            'sheetPathLevel',
            'sheetVampire',
            'sheetWillpower',
            'sheetFreebiesVirtues',
            'sheetFreebiesPath',
            'sheetFreebiesWillpower'
        ],
        data() {
            return {
                vampire: false,
                virtues: [],
                lastVirtues: [],
                path: '',
                pathLevel: null,
                willpower: null,
                virtuesSpent: 0,
                totalVirtues: 0,
                minimumMorality: 0,
                minimumWillpower: 0
            }
        },
        watch: {
            'sheetVirtues': {
                handler: function() {
                    this.virtues = this.sheetVirtues;
                },
                deep: true
            },
            'sheetPathLevel': function() {
                this.pathLevel = this.sheetPathLevel;
            },
            'sheetVampire': function() {
                this.vampire = this.sheetVampire;
                if (!this.vampire) {
                    this.virtues[0].name = 'Conscience';
                    this.virtues[1].name = 'Self-Control';
                }
            },
            'virtues': {
                handler: function() {
                    let totalVirtues = 0;
                    let lockedMax = 7;

                    this.virtues.forEach((v,k) => {
                        totalVirtues += v.level;

                        if (!this.virtues[k].lock) {
                            this.virtues[k].lock = 1;
                        }

                        if (v.name !== 'Conviction' && v.name !== 'Instinct') {
                            totalVirtues -= 1;
                            lockedMax += 1;
                        }
                        this.virtues[k].lock = Math.min(this.virtues[k].lock, this.virtues[k].level);
                    });

                    let totalCurrentLocked = this.virtues.reduce((a,c) => a + c.lock, 0);

                    if (this.lastVirtues && this.lastVirtues.length > 0 && lockedMax > totalCurrentLocked) {
                        let lockCount = lockedMax;
                        this.virtues.forEach((v, k) => {
                            if (v.level === this.lastVirtues[k].level) {
                                lockCount -= v.lock;
                            } else if (k < 2 && lockCount > v.level) {
                                this.virtues[k].lock = v.level;
                                lockCount -= v.level;
                            }  else if (k === 2 && lockCount >= v.level) {
                                this.virtues[k].lock = v.level;
                                lockCount -= v.level;
                            } else {
                                this.virtues[k].lock = lockCount;
                                lockCount -= lockCount;
                            }
                        });
                    }


                    totalCurrentLocked = this.virtues.reduce((a,c) => a + c.lock, 0);

                    if (totalCurrentLocked < lockedMax && totalCurrentLocked < this.virtues.reduce((a,c) => a + c.level, 0)) {
                        let lockCount = lockedMax - totalCurrentLocked;
                        this.virtues.forEach((v, k) => {
                            if (v.level < 5 && lockCount > 0 && v.level !== v.lock) {
                                let add = Math.min(5 - v.level, lockCount);
                                this.virtues[k].lock += add;
                                lockCount -= add;
                            }
                        });
                    } else if (lockedMax < totalCurrentLocked) {
                        this.virtues.forEach((v,k) => {
                            if (this.lastVirtues[k].name !== v.name) {
                                this.virtues[k].lock--;
                            }
                        });
                    }

                    this.minimumMorality = this.virtues[0].lock + this.virtues[1].lock;
                    this.minimumWillpower = this.virtues[2].lock;

                    this.virtuesSpent = totalVirtues;

                    this.lastVirtues = JSON.parse(JSON.stringify(this.virtues));
                    this.$emit('update:sheetVirtues', this.virtues);
                    this.$emit('update:sheetFreebiesVirtues', Math.max((this.virtuesSpent - 7) * 2, 0));
                },
                deep: true
            },
            'path': function() {
                this.$emit('update:sheetPath', this.path);
            },
            'pathLevel': function() {
                this.$emit('update:sheetPathLevel', this.pathLevel);
                this.$emit('update:sheetFreebiesPath', (this.pathLevel - this.minimumMorality) * 2);
            },
            'willpower': function() {
                this.$emit('update:sheetWillpower', this.willpower);
                this.$emit('update:sheetFreebiesWillpower', this.willpower - this.minimumWillpower);
            }
        },
        methods: {
        },
        mounted() {
            this.virtues = this.sheetVirtues;
            this.willpower = this.sheetWillpower;
            this.path = this.sheetPath;
            this.vampire = this.sheetVampire;
        }
    });
</script>

<style lang="scss">
    .path-wrap, .willpower-wrap {
        > * {
            display: block !important;
            margin: 4px !important;
            //text-align: center;
        }
        label,.label {
            width: 180px !important;
            font-size: 1em!important;
        }
        label {
            font-weight: bold;
        }
    }
</style>