<template>
    <div>
        <h2>Character Details</h2>
        <div class="sheet-block">
            <div class="two-third-column">
                <div class="input-wrap std-padding-top2">
                    <label for="name">Name</label>
                    <input type="text" v-model="details.name" id="name" placeholder="Name">
                </div>
                <div class="input-wrap">
                    <label for="type">Type</label>
                    <select v-model="details.chartype" id="type">
                        <option value="Vampire">Vampire</option>
                        <option value="Ghoul">Ghoul</option>
                        <option value="Mortal">Mortal</option>
                    </select>
                </div>

                <div class="input-wrap" v-show="details.chartype && details.chartype === 'Vampire'">
                    <label for="clan">Clan/Bloodline</label>
                    <input type="text" v-model="details.clan" id="clan" placeholder="Clan/Bloodline">
                </div>
                <div class="input-wrap" v-show="details.chartype">
                    <label for="sect">Sect</label>
                    <select v-model="details.sect" id="sect">
                        <option value="Anarch" v-if="details.chartype !== 'Mortal'">Anarch</option>
                        <option value="Camarilla" v-if="details.chartype !== 'Mortal'">Camarilla</option>
                        <option value="None" v-if="details.chartype === 'Mortal'">None</option>
                    </select>
                </div>
                <div class="input-wrap" v-show="details.chartype && details.chartype !== 'Mortal'">
                    <label for="name">Sire/Domitor</label>
                    <input type="text" v-model="details.sire" id="sire" placeholder="Sire/Domitor">
                </div>
                <div class="input-wrap std-padding-top2" v-show="details.chartype">
                    <label for="nature">Nature</label>
                    <input type="text" v-model="details.nature" id="nature" placeholder="Nature">
                </div>
                <div class="input-wrap" v-show="details.chartype">
                    <label for="demeanor">Demeanor</label>
                    <input type="text" v-model="details.demeanor" id="demeanor" placeholder="Demeanor">
                </div>
                <div class="input-wrap std-padding-top2" v-show="details.chartype">
                    <label for="weakness">Clan Weakness</label>
                    <input type="text" class="long" v-model="details.weakness" id="weakness" placeholder="Clan Weakness (if needed)">
                </div>
                <div class="input-wrap" v-show="details.chartype">
                    <label for="concept">Concept</label>
                    <input type="text" class="long" v-model="details.concept" id="concept" placeholder="Concept">
                </div>
            </div>
            <div class="third-column">
                <div class="input-wrap">
                    <label for="freebies">Freebies available</label>
                    <input type="text" class="short" maxlength="3" value="30" v-model="details.freebies" id="freebies">
                    <span class="sub">Default {{ details.chartype === 'Vampire' ? 30 : 21 }}</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'SheetDetails',
        data() {
            return {
                details: { }
            }
        },
        props: [
            'sheetDetails'
        ],
        watch: {
            sheetDetails: {
                handler: function() {
                    this.details = this.sheetDetails;
                },
                deep: true
            },
            details: {
                handler: function() {
                    this.$emit('update:sheetDetails', this.details);
                },
                deep: true
            },
            'details.chartype': function() {
                this.details.freebies = this.details.chartype === 'Vampire' ? 30 : 21;
                if (this.details.chartype === 'Mortal') {
                    this.details.sect = 'None';
                } else if (this.details.sect === 'None') {
                    this.details.sect = '';
                }
            }
        },
        mounted() {
            this.details = this.sheetDetails;
        }
    }

</script>