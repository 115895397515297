<template>
    <div class="third-column">
        <h4>Merits</h4>
        <span class="add-row" v-on:click="addMerit()">+ Add a merit</span>
        <div v-for="(merit, i) in merits" :key="'merit-'+i" class="input-wrap specialization-wrap">
            <input type="text" class="label" v-model="merit[0]" placeholder="Merit name">
            <select v-model="merit[1]">
                <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
            </select>
            <span class="close" v-on:click="deleteRow('merits', i)"><i class="fa fa-trash"></i></span>
            <input type="text" class="specialization" v-model="merit[2]" placeholder="Details" />
            <input type="text" class="specialization" v-model="merit[3]" placeholder="Source book reference" />
        </div>
        <h4>Flaws</h4>
        <span class="add-row" v-on:click="addFlaw()">+ Add a flaw</span>
        <div v-for="(flaw, i) in flaws" :key="'flaw-'+i" class="input-wrap specialization-wrap">
            <input type="text" class="label" v-model="flaw[0]" placeholder="Flaw name">
            <select v-model="flaw[1]">
                <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
            </select>
            <span class="close" v-on:click="deleteRow('flaws', i)"><i class="fa fa-trash"></i></span>
            <input type="text" class="specialization" v-model="flaw[2]" placeholder="Details" />
            <input type="text" class="specialization" v-model="flaw[3]" placeholder="Source book reference" />
        </div>
        <span class="sub">Please provide a source book reference for all merits and flaws including page number.</span>

        <div class="points-summary" v-show="merits.length > 0 || flaws.length > 0">
            <div class="freebies">
                <span class="label">Freebies {{ meritsSpent - flawsSpent >= 0 ? 'spent' : 'earned' }}:</span>
                <span class="number">{{ meritsSpent - flawsSpent }}</span>
            </div>
        </div>


    </div>
</template>

<script>


    export default({
        name: 'SheetMerits',
        components: {
        },
        data() {
            return {
                merits: [],
                flaws: [],
                meritsSpent: 0,
                flawsSpent: 0
            }
        },
        props: [
            'sheetMerits',
            'sheetFlaws',
            'sheetFreebiesMerits',
            'sheetFreebiesFlaws'
        ],
        computed: {
        },
        methods: {
            addMerit: function() {
                this.merits.push(['',0,'','']);
            },
            addFlaw: function() {
                this.flaws.push(['',0,'','']);
            },
            deleteRow: function(type, i) {
                if (type === 'flaws') {
                    this.flaws = this.flaws.filter(function (v, k) {
                        return k !== i;
                    });
                } else {
                    this.merits = this.merits.filter(function (v, k) {
                        return k !== i;
                    });
                }
            },
            calculateMerits: function() {

                this.meritsSpent = this.merits.reduce((a,c) => a + c[1], 0);
                this.$emit('update:sheetMerits', this.merits);
                this.$emit('update:sheetFreebiesMerits', this.meritsSpent);
            },
            calculateFlaws: function() {
                this.flawsSpent = Math.min(this.flaws.reduce((a,c) => a + c[1], 0),7);
                this.$emit('update:sheetFlaws', this.flaws);
                this.$emit('update:sheetFreebiesFlaws', this.flawsSpent);
            }
        },
        watch: {
            sheetMerits: {
                handler: function() {
                    this.merits = this.sheetMerits;
                },
                deep: true
            },
            sheetFlaws: {
                handler: function() {
                    this.flaw = this.sheetFlaws;
                },
                deep: true
            },
            merits: {
                handler: function() {
                    this.calculateMerits();
                },
                deep: true
            },
            flaws: {
                handler: function() {
                    this.calculateFlaws();
                },
                deep: true
            }
        },
        mounted() {
            this.merits = this.sheetMerits;
            this.flaws = this.sheetFlaws;

            this.freebiesMerits = this.sheetFreebiesMerits;
            this.freebiesFlaws = this.sheetFreebiesFlaws;
        }
    });
</script>

<style lang="scss" scoped>
    .input-wrap {
        select {
            width: 60px!important;
        }
    }

</style>