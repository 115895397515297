<template>
    <div class="third-column">
        <h4>Disciplines</h4>
        <span class="add-row" v-on:click="addDiscipline()">+ Add a discipline</span>
        <div v-for="(disc, i) in disciplines" :key="i" class="input-wrap specialization-wrap">
            <select class="label" v-model="disc[0]" placeholder="Discipline name">
                <option></option>
                <option v-for="(dname,k) in disciplineList" :key="'dname-'+k" :value="dname">{{ dname }}</option>
            </select>
            <dots :level.sync="disc[1]" :minimum="0" :maximum="5" />
            <span class="close" v-on:click="deleteRow(i)"><i class="fa fa-trash"></i></span>
            <input type="text" class="specialization" v-model="disc[2]" placeholder="Path" v-show="disc[0] === 'Thaumaturgy' || disc[0] === 'Necromancy'" />
        </div>

        <div class="points-summary" :class="{'available' : Math.max(disciplinesAvailable - disciplinePoints, 0) > 0 }">
            <div class="points">
                <span class="label">Points remain:</span>
                <span class="number">{{ Math.max(disciplinesAvailable - disciplinePoints, 0) }}</span>
            </div>
            <div class="freebies" v-show="Math.max(disciplinePoints - disciplinesAvailable, 0) > 0">
                <span class="label">Freebies used:</span>
                <span class="number">{{ Math.max(disciplinePoints - disciplinesAvailable, 0) * 7 }}</span>
            </div>
            <div class="warning"><i class="fa fa-exclamation-circle"></i></div>
        </div>
    </div>
</template>

<script>

    import Dots from './parts/Dots'

    export default({
        name: 'SheetDisciplines',
        components: {
            Dots
        },
        data() {
            return {
                disciplines: [],
                paths: [],
                type: '',
                disciplineList: [
                    'Animalism',
                    'Auspex',
                    'Celerity',
                    'Dominate',
                    'Fortitude',
                    'Obfuscate',
                    'Presence',
                    'Potence',
                    'Chimestry',
                    'Dementation',
                    'Necromancy',
                    'Obtenebration',
                    'Protean',
                    'Quietus',
                    'Serpentis',
                    'Thaumaturgy',
                    'Vicissitude',
                    'Flight',
                    'Melpominee',
                    'Mytherceria',
                    'Thanatosis',
                    'Visceratika',
                    'Obeah',
                    'Temporis',
                    'Valeran'

                ]
            }
        },
        props: [
            'sheetDisciplines',
            'sheetType',
            'sheetFreebies'
        ],
        computed: {
            disciplinesAvailable: function() {
                switch (this.type) {
                    case 'Vampire':
                        return 3;
                    case 'Ghoul':
                        return 2;
                    default:
                        return 0;
                }
            },
            disciplinePoints: function() {
                return this.disciplines.reduce((a,c) => a + c[1], 0);
            }
        },
        methods: {
            addDiscipline: function() {
                this.disciplines.push(['',0,'']);
            },
            deleteRow: function(i) {
                this.disciplines = this.disciplines.filter(function(v,k) { return k !== i; });
            }
        },
        watch: {
            sheetDisciplines: {
                handler: function() {
                    this.disciplines = this.sheetDisciplines;
                },
                deep: true
            },
            sheetType: function() {
                this.type = this.sheetType;
            },
            disciplines: {
                handler: function() {
                    this.$emit('update:sheetDisciplines', this.disciplines);
                    this.$emit('update:sheetFreebies', Math.max(this.disciplinePoints - this.disciplinesAvailable, 0) * 7);
                },
                deep: true
            }
        },
        mounted() {
            this.type = this.sheetType;
        }
    });
</script>