<template>
    <div>
        <h2>Abilities</h2>
        <div class="sheet-block">
            <div class="third-column" v-for="(abilBlock, index) in abilities" :key="index">
                <h4>{{ index }}</h4>
                <div v-for="(abil, i) in abilBlock" :key="i" class="input-wrap specialization-wrap">
                    <label :for="i">{{ i }}</label>
                    <dots :level.sync="abil[0]" :minimum="0" :maximum="5" />
                    <input type="text" class="specialization" v-model="abil[1]" placeholder="Specialization" />
                </div>
            </div>

            <div class="single-column">
                <span class="sub">If you have multiple crafts, subsets of abilities, etc. please note them below.</span>
            </div>

            <div class="third-column" v-for="(abilBlock, index) in additionalAbilities" :key="'add' + index">
                <h4>Additional {{ index }}</h4>
                <span class="add-row" v-on:click="addAdditionalAbility(index)">+ Add an ability</span>
                <div v-for="(abil, i) in abilBlock" :key="i" class="input-wrap specialization-wrap">
                    <input type="text" class="label" v-model="abil[0]" placeholder="Ability name">
                    <dots :level.sync="abil[1]" :minimum="0" :maximum="5" />
                    <input type="text" class="specialization" v-model="abil[2]" placeholder="Specialization" />
                    <span class="close" v-on:click="deleteRow(index, i)"><i class="fa fa-trash"></i></span>
                </div>


                <div class="points-summary" v-bind:class="{'available' : Math.max(abilityOrder[index].pointsAvailable - abilityOrder[index].pointsSpent, 0) > 0 }">
                    <div class="order order-primary" v-if="abilityOrder[index].order === 1">Primary</div>
                    <div class="order order-secondary" v-if="abilityOrder[index].order === 2">Secondary</div>
                    <div class="order order-tertiary" v-if="abilityOrder[index].order === 3">Tertiary</div>
                    <div class="points">
                        <span class="label">Points remain:</span>
                        <span class="number">{{ Math.max(abilityOrder[index].pointsAvailable - abilityOrder[index].pointsSpent, 0) }}</span>
                    </div>
                    <div class="freebies" v-show="abilityOrder[index].freebiesSpent > 0">
                        <span class="label">Freebies used:</span>
                        <span class="number">{{ abilityOrder[index].freebiesSpent }}</span>
                    </div>
                    <div class="warning"><i class="fa fa-exclamation-circle"></i></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import Dots from './parts/Dots'

    export default {
        name: 'SheetAbilities',
        data() {
            return {
                vampire: true,
                abilities: {},
                additionalAbilities: {}
            }
        },
        components: {
            Dots
        },
        computed: {
            abilityOrder: function () {

                let availablePoints = this.vampire ? [13, 9, 5] : [12, 7, 4];
                let keys = Object.keys(this.abilities);
                let obj = {};


                if (keys) {
                    let arr = [];
                    keys.forEach((v) => {
                        arr.push({
                            index: v,
                            order: 0,
                            pointsSpent: this.pointsSpent(v, false),
                            pointsSpentFreebiesOnly: this.pointsSpent(v, true),
                            freebiesSpent: 0
                        });
                    });

                    arr.sort(function(a,b) {
                        return a.pointsSpent <= b.pointsSpent ? 1 : -1;
                    });

                    arr.forEach((v,k) => {
                        arr[k].pointsAvailable = availablePoints[k];
                        arr[k].order = k + 1;
                        arr[k].freebiesSpent = Math.max((arr[k].pointsAvailable - arr[k].pointsSpent) * -2, 0) + (arr[k].pointsSpentFreebiesOnly * 2);
                        obj[arr[k].index] = arr[k];
                    });
                }
                return obj;
            }
        },
        methods: {
            pointsSpent: function(type, freebiesOnly) {
                let combinedAbilities = { ...this.abilities[type], ...this.additionalAbilities[type] };
                let total = 0;

                Object.keys(combinedAbilities).forEach((v) => {
                    let points = combinedAbilities[v].length === 3 ? combinedAbilities[v][1] : combinedAbilities[v][0];

                    if (!freebiesOnly) {
                        total += Math.min(points, 3);
                    } else {
                        total += Math.max(points - 3, 0);
                    }
                });

                return total;
            },
            addAdditionalAbility: function(index) {
                if (this.additionalAbilities[index].length < 5) {
                    this.additionalAbilities[index].push(['', 0, '']);
                }
            },
            deleteRow: function(index, i) {
                this.additionalAbilities[index] = this.additionalAbilities[index].filter(function(v,k) { return k !== i; });
            }
        },
        props: [ 'sheetAbilities', 'sheetAdditionalAbilities', 'sheetVampire', 'sheetFreebies' ],
        watch: {
            sheetVampire: function() {
                this.vampire = this.sheetVampire;
            },
            sheetAbilities: function() {
                this.abilities = this.sheetAbilities;
            },
            sheetAdditionalAbilities: function() {
                this.additionalAbilities = this.sheetAdditionalAbilities;
            },
            abilities: {
                handler: function() {
                    this.$emit('update:sheetAbilities', this.abilities);
                    this.$emit('update:sheetFreebies', [ this.abilityOrder.Talents.freebiesSpent, this.abilityOrder.Skills.freebiesSpent, this.abilityOrder.Knowledges.freebiesSpent ])

                },
                deep: true
            },
            additionalAbilities: {
                handler: function() {
                    this.$emit('update:sheetAdditionalAbilities', this.additionalAbilities);
                    this.$emit('update:sheetFreebies', [ this.abilityOrder.Talents.freebiesSpent, this.abilityOrder.Skills.freebiesSpent, this.abilityOrder.Knowledges.freebiesSpent ])
                },
                deep: true
            }
        },
        mounted() {
            this.abilities = this.sheetAbilities;
            this.additionalAbilities = this.sheetAdditionalAbilities;
            this.vampire = this.sheetVampire;
        }
    }

</script>

<style lang="scss">
    .add-row {
        color: red;
        font-size: 0.8em;
        display: block;
        padding: 4px 8px;
        text-decoration: underline;
        cursor: pointer;
    }
</style>