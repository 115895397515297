<template>
    <div class="sheet">
        <a href="/">Back to Character List</a>

        <div class="popup" v-if="exportSheet">
            <div class="wrap">
                <div class="close-popup" v-on:click="exportSheet = null"><i class="fa fa-close"></i> Close Window
                </div>
                <div class="content">

                    <br>
                    <strong>You can copy all of the below text directly into the forums</strong>
                    <br><br>

                    <pre>{{ exportSheet }}</pre>
                </div>
            </div>
        </div>



        <sheet-details :sheet-details.sync="sheet.details"/>
        <sheet-attributes :sheet-attributes.sync="sheet.attributes"
                          :sheet-vampire.sync="isVampire"
                          :sheet-freebies.sync="sheet.freebies.attributes"
                          v-if="sheet && sheet.freebies"
                          v-show="sheet.details && sheet.details.chartype"/>

        <sheet-abilities :sheet-abilities.sync="sheet.abilities"
                         :sheet-additional-abilities="sheet.additionalAbilities"
                         :sheet-freebies.sync="sheet.freebies.abilities"
                         :sheet-vampire.sync="isVampire"
                         v-if="sheet && sheet.freebies"
                         v-show="sheet.details && sheet.details.chartype"/>

        <div v-if="sheet.details && sheet.details.chartype">
            <h2>Advantages</h2>
            <div class="sheet-block">
                <sheet-backgrounds :sheet-backgrounds.sync="sheet.backgrounds"
                                   :sheet-freebies.sync="sheet.freebies.backgrounds"
                                   :sheet-vampire.sync="isVampire"/>

                <sheet-disciplines :sheet-disciplines.sync="sheet.disciplines"
                                   :sheet-type.sync="sheet.details.chartype"
                                   :sheet-freebies.sync="sheet.freebies.disciplines"
                                   v-if="sheet.details.chartype !== 'Mortal'"/>

                <sheet-morality :sheet-willpower.sync="sheet.willpower"
                                :sheet-virtues.sync="sheet.virtues"
                                :sheet-path.sync="sheet.path"
                                :sheet-path-level.sync="sheet.pathLevel"
                                :sheet-freebies-virtues.sync="sheet.freebies.virtues"
                                :sheet-freebies-path.sync="sheet.freebies.path"
                                :sheet-freebies-willpower.sync="sheet.freebies.willpower"
                                :sheet-vampire.sync="isVampire"/>

                <sheet-merits :sheet-merits.sync="sheet.merits"
                              :sheet-flaws.sync="sheet.flaws"
                              :sheet-freebies-merits.sync="sheet.freebies.merits"
                              :sheet-freebies-flaws.sync="sheet.freebies.flaws"/>

                <sheet-rituals :sheet-rituals.sync="sheet.rituals"
                               v-if="sheet.details.chartype !== 'Mortal'"/>

                <sheet-equipment :sheet-equipment.sync="sheet.equipment"/>
            </div>
        </div>

        <div class="control-panel">
            <div v-if="unsaved" class="unsaved">There are unsaved changes on this sheet.</div>
            <button class="save" v-on:click="saveSheet()" v-if="unsaved">Save Changes</button>
            <button class="export" v-on:click="exportSheetToForum()">Export to Forum Format</button>

            <div class="freebies" v-bind:class="{'freebies-overspent' : freebiesAvailable < 0 }">
                <div class="flex"></div>
                <div class="number">{{ freebiesAvailable }}</div>
                <div>
                    <span class="title">freebies remaining</span>
                    <span class="overspent">{{ freebiesAvailable >= 0 ? '' : 'Overspent' }}</span>
                </div>
                <div class="flex"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import SheetDetails from '../components/sheet/Details'
    import SheetAttributes from '../components/sheet/Attributes'
    import SheetAbilities from '../components/sheet/Abilities'
    import SheetBackgrounds from '../components/sheet/Backgrounds'
    import SheetDisciplines from '../components/sheet/Disciplines'
    import SheetMorality from '../components/sheet/Morality'
    import SheetMerits from '../components/sheet/Merits'
    import SheetRituals from '../components/sheet/Rituals'
    import SheetEquipment from '../components/sheet/Equipment'

    export default {
        name: 'SheetView',
        data() {
            return {
                characterID: null,
                sheet: {},
                sheetBack: {},
                freebiesAvailable: 0,
                exportSheet: null
            }
        },
        components: {
            SheetDetails,
            SheetAttributes,
            SheetAbilities,
            SheetBackgrounds,
            SheetDisciplines,
            SheetMorality,
            SheetMerits,
            SheetRituals,
            SheetEquipment
        },
        computed: {
            isVampire: function () {
                return this.sheet.details && this.sheet.details.chartype && this.sheet.details.chartype === 'Vampire';
            },
            unsaved: function () {
                return JSON.stringify(this.sheet) !== JSON.stringify(this.sheetBack);
            }
        },
        props: {},
        methods: {
            saveSheet: function () {
                if (!localStorage.characters) {
                    this.$router.push('/');
                }
                let characters = JSON.parse(localStorage.getItem('characters'));
                characters[this.characterID] = this.sheet;
                localStorage.setItem('characters', JSON.stringify(characters));
                this.sheetBack = JSON.parse(JSON.stringify(this.sheet));
            },
            exportSheetToForum: function () {
                let file = this.generateFileContents();
                let spacer = ' ';
                let str = '';

                file.forEach((v) => {
                    if (v[0]) {
                        str += v[0] + ':' + spacer;
                    }
                    if (v[1]) {
                        str += v[1];
                    }
                    if (v[2]) {
                        for (let i = 0; i < v[2]; i++) {
                            str += '\n';
                        }
                    }
                });

                this.exportSheet = str;

            },
            generateFileContents: function () {
                let fileComponents = [];
                let name = this.sheet.details.name.trim() === '' ? '[UNNAMED]' : this.sheet.details.name.trim();

                fileComponents.push(['Name', name, 1]);
                fileComponents.push(['Type', this.sheet.details.chartype.trim() || '', 1]);
                fileComponents.push(['Clan', (this.sheet.details.chartype === 'Vampire' ? this.sheet.details.clan.trim() : ''), 1]);
                fileComponents.push([(this.sheet.details.chartype === 'Vampire' ? 'Sire' : 'Domitor'), +this.sheet.details.sire.trim() || '', 1]);
                fileComponents.push(['Sect', (this.sheet.details.chartype !== 'Mortal' ? this.sheet.details.sect.trim() : ''), 1]);
                fileComponents.push(['Nature', this.sheet.details.nature.trim() | '', 1]);
                fileComponents.push(['Demeanor', this.sheet.details.demeanor.trim() || '', 1]);
                fileComponents.push(['Concept', this.sheet.details.concept.trim() || '', 1]);
                fileComponents.push(['Clan Weakness', (this.sheet.details.chartype === 'Vampire' ? this.sheet.details.weakness.trim() || '' : ''), 3]);

                Object.keys(this.sheet.attributes).forEach((index) => {
                    fileComponents.push([null, index.toUpperCase(), 1]);
                    Object.keys(this.sheet.attributes[index]).forEach((k) => {
                        let val = this.sheet.attributes[index][k][0];
                        if (this.sheet.attributes[index][k][1].trim() !== '') {
                            val += ' - ' + this.sheet.attributes[index][k][1].trim();
                        }
                        fileComponents.push([k, val, 1]);
                    });
                    fileComponents.push([null, null, 1]);
                });
                fileComponents.push([null, null, 1]);


                Object.keys(this.sheet.abilities).forEach((index) => {
                    fileComponents.push([null, index.toUpperCase(), 1]);
                    Object.keys(this.sheet.abilities[index]).forEach((k) => {
                        let val = this.sheet.abilities[index][k][0];
                        if (this.sheet.abilities[index][k][1].trim() !== '') {
                            val += ' - ' + this.sheet.abilities[index][k][1].trim();
                        }
                        fileComponents.push([k, val, 1]);
                    });

                    this.sheet.additionalAbilities[index].forEach((k) => {
                        if (k[0].trim() !== '') {
                            let val = k[1];
                            if (k[2].trim() !== '') {
                                val += ' - ' + k[2].trim();
                            }
                            fileComponents.push(['*' + k[0].trim(), val, 1]);
                        }
                    });

                    fileComponents.push([null, null, 1]);


                });
                fileComponents.push([null, null, 1]);


                fileComponents.push([null, 'BACKGROUNDS', 1]);
                this.sheet.backgrounds.forEach((v) => {
                    let val = v[1];
                    if (v[2].trim() !== '') {
                        val += ' - ' + v[2].trim();
                    }
                    fileComponents.push([v[0], val, 1]);
                });
                fileComponents.push([null, null, 2]);


                fileComponents.push([null, 'DISCIPLINES', 1]);
                this.sheet.disciplines.forEach((v) => {
                    let val = v[1];
                    if (v[2].trim() !== '') {
                        val += ' - ' + v[2].trim();
                    }
                    fileComponents.push([v[0], val, 1]);
                });
                fileComponents.push([null, null, 2]);

                fileComponents.push([null, 'MORALITY', 1]);
                fileComponents.push([this.sheet.path.trim() !== '' ? this.sheet.path.trim() : '[UNKNOWN PATH]', this.sheet.pathLevel, 2]);
                fileComponents.push([this.sheet.virtues[0].name, this.sheet.virtues[0].level + ' [base: ' + this.sheet.virtues[0].lock + ']', 1]);
                fileComponents.push([this.sheet.virtues[1].name, this.sheet.virtues[1].level + ' [base: ' + this.sheet.virtues[1].lock + ']', 1]);
                fileComponents.push([this.sheet.virtues[2].name, this.sheet.virtues[2].level + ' [base: ' + this.sheet.virtues[2].lock + ']', 2]);
                fileComponents.push(['Willpower', this.sheet.willpower, 3]);

                fileComponents.push([null, 'RITUALS', 1]);
                this.sheet.rituals.forEach((v) => {
                    if (v[0].trim() !== '') {
                        let val = v[1];
                        if (v[2].trim() !== '') {
                            val += ' [' + v[2].trim() + ']';
                        }
                        fileComponents.push([v[0].trim(), val, 1]);
                    }
                });
                fileComponents.push([null, null, 2]);

                fileComponents.push([null, 'MERITS', 1]);
                this.sheet.merits.forEach((v) => {
                    if (v[0].trim() !== '') {
                        let val = v[1];
                        if (v[2].trim() !== '') {
                            val += ' - ' + v[2].trim();
                        }
                        fileComponents.push([v[0].trim(), val, 1]);
                    }
                });
                fileComponents.push([null, null, 2]);

                fileComponents.push([null, 'FLAWS' + (this.sheet.freebies.flaws >= 7 ? ' - MAXED' : ''), 1]);
                this.sheet.flaws.forEach((v) => {
                    if (v[0].trim() !== '') {
                        let val = v[1];
                        if (v[2].trim() !== '') {
                            val += ' - ' + v[2].trim();
                        }
                        if (v[3].trim() !== '') {
                            val += ' [' + v[3].trim() + ']';
                        }
                        fileComponents.push([v[0].trim(), val, 1]);
                    }
                });
                fileComponents.push([null, null, 2]);


                fileComponents.push([null, 'Equipment', 1]);
                this.sheet.equipment.forEach((v) => {
                    if (v.trim() !== '') {
                        fileComponents.push([v.trim(), null, 1]);
                    }
                });
                fileComponents.push([null, null, 3]);

                fileComponents.push([null, 'FREEBIES SPENT', 1]);
                this.sheet.freebies.attributes.forEach((v, k) => {
                    let labels = ['Physical', 'Social', 'Mental'];
                    if (v > 0) {
                        fileComponents.push([labels[k], v, 1]);
                    }
                });
                this.sheet.freebies.abilities.forEach((v, k) => {
                    let labels = ['Talents', 'Skills', 'Knowledges'];
                    if (v > 0) {
                        fileComponents.push([labels[k], v, 1]);
                    }
                });

                if (this.sheet.freebies.backgrounds > 0) {
                    fileComponents.push(['Backgrounds', this.sheet.freebies.backgrounds, 1]);
                }

                if (this.sheet.freebies.disciplines > 0) {
                    fileComponents.push(['Disciplines', this.sheet.freebies.disciplines, 1]);
                }

                if (this.sheet.freebies.virtues > 0) {
                    fileComponents.push(['Virtues', this.sheet.freebies.virtues, 1]);
                }

                if (this.sheet.freebies.path > 0) {
                    fileComponents.push(['Path/Humanity', this.sheet.freebies.path, 1]);
                }

                if (this.sheet.freebies.willpower > 0) {
                    fileComponents.push(['Willpower', this.sheet.freebies.willpower, 1]);
                }

                if (this.sheet.freebies.merits > 0) {
                    fileComponents.push(['Merits', this.sheet.freebies.merits, 1]);
                }

                if (this.sheet.freebies.flaws > 0) {
                    fileComponents.push(['Merits', this.sheet.freebies.flaws * -1, 1]);
                }

                fileComponents.push([null, null, 1]);
                fileComponents.push(['TOTAL SPENT', this.sheet.details.freebies - this.freebiesAvailable, 1]);

                return fileComponents;
            }
        },
        watch: {
            sheet: {
                handler: function () {
                    let freebiesAvailable = this.sheet.details.freebies;
                    Object.keys(this.sheet.freebies).forEach((k) => {
                        if (k === 'attributes' || k === 'abilities') {
                            freebiesAvailable -= this.sheet.freebies[k].reduce((a, c) => a + c, 0);
                        } else if (k === 'flaws') {
                            freebiesAvailable += this.sheet.freebies.flaws;
                        } else {
                            freebiesAvailable -= this.sheet.freebies[k];
                        }
                    });
                    this.freebiesAvailable = freebiesAvailable;
                },
                deep: true
            }
        },
        created() {
            if (!localStorage.characters) {
                this.$router.push('/');
            }

            let id = this.$route.params.id - 1;

            let characters = JSON.parse(localStorage.getItem('characters'));

            if (characters[id]) {
                this.characterID = id;
                this.sheet = characters[id];
                this.sheetBack = JSON.parse(JSON.stringify(this.sheet));
            } else {
                this.$router.push('/');
            }

        }
    }
</script>

<style lang="scss">

    a {
        color: red;
        font-size: 0.8em;
    }

    .popup {
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        max-width: 100vw;
        padding: 0;
        background: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        align-items: center;

        .close-popup {
            position: absolute;
            left: 0;
            top: 0;
            padding: 4px;
            background: red;
            color: white;
        }

        .wrap {
            width: 90vw;
            height: 90vh;
            max-height: 90vh;
            max-width: 90vw;
            margin: 0 auto;
            overflow: auto;

            .content {
                flex: 1 1 auto;
                padding: 16px;
                background: white;

                pre {
                    font-family: monospace;
                    font-size: 14px;
                }
            }
        }
    }

    .control-panel {
        width: 100vw;
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 2px solid #222;
        background: #eee;
        display: flex;
        flex-wrap: wrap;


        .unsaved {
            width: 100%;
            display: block;
            padding: 6px;
            font-weight: bold;
            font-size: 1.1em;
            color: #f33;

            @media only screen and (max-width: 560px) {
                font-size: 1em;
            }
        }

        button, .freebies {
            border: none;
            background: white;
            margin: 8px;
            @media only screen and (max-width: 560px) {
                width: 100%;
                margin: 4px;
            }
        }

        button {
            padding: 16px;
            display: block;
            cursor: pointer;
            font-weight: bold;

            &.save {
                background: #f33;
                color: white;
            }

            &.export {
                background: #099;
                color: white;
            }
        }

        .freebies {
            padding: 10px 16px;
            display: flex;

            > div {
                margin: 0 auto;
                display: block;
                vertical-align: middle;

                &.number {
                    line-height: 26px;
                    font-size: 26px;
                    font-weight: bold;
                    padding-right: 12px;
                }

                .title, .overspent {
                    font-size: 0.7em;
                    display: block;
                }

                .overspent {
                    font-weight: bold;
                    color: aqua;
                }

                &.flex {
                    flex: 1 1 auto;
                }
            }

            &.freebies-overspent {
                background: red;
                color: white;

                > div {
                    &.number {
                        color: aqua;
                    }
                }
            }
        }
    }


    .sheet {
        padding-bottom: 120px;
        @media only screen and (max-width: 560px) {
            padding-bottom: 240px;
        }

        h4 {
            color: #333;
            margin: 6px;
            border-bottom: 1px solid #333;
            padding: 4px;
        }


        .sheet-block {
            padding: 8px;
            border: 1px solid #999;
            border-radius: 5px;
            display: flex;
            flex-wrap: wrap;

            .single-column, .two-third-column, .third-column {
                > div {
                    padding: 8px;
                }
            }

            .single-column {
                flex: 1 1 100%;
            }

            .two-third-column {
                flex: 1 1 66%;
            }

            .third-column {
                flex: 1 1 33%;
                min-width: 240px;
            }
        }


        .points-summary {
            margin: 16px 2px 0;
            background: #333;
            color: white;
            position: relative;

            .warning {
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                font-size: 38px;
                height: 38px;
                width: 38px;
                color: yellow;
            }

            &.available {
                .warning {
                    display: block
                }
            }

            .order {
                font-size: 0.8em;
                font-weight: bold;

                &.order-primary {
                    color: #9f6;
                }

                &.order-secondary {
                    color: #6af;
                }

                &.order-tertiary {
                    color: #f69;
                }
            }

            .points, .freebies {
                > * {
                    line-height: 28px;
                    display: inline-block;
                    vertical-align: middle;
                }

                .label {
                    font-size: 0.8em;
                    font-weight: bold;
                    width: 130px;
                }

                .number {
                    font-size: 1.4em;
                }
            }

            .freebies {
                .label {
                    color: #ccc;
                }

                .number {
                    color: #f66;
                }
            }
        }

        span.close {
            display: inline-block;
            margin: 0 8px;
            font-size: 1.2em;
            color: #aaa;
            cursor: pointer;

            &:hover {
                color: black;
            }

            i {
                line-height: 24px;
            }
        }

        span.sub {
            padding-left: 4px;
            display: inline-block;
            color: #666;
            font-size: 0.8em;
            max-width: 80%;
            margin-top: 16px;
            padding-top: 8px;
            border-top: 1px solid #ccc;
        }


        .input-wrap {
            padding: 4px;

            span.sub {
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }

            > * {
                vertical-align: middle;
            }

            label {
                display: inline-block;
                width: 120px;
                font-size: 0.9em;
                margin-right: 6px;
            }

            input, select {
                padding: 4px;
            }

            input {
                font-size: 0.8em;
                border: solid black;
                border-width: 0 0 1px;

                &::placeholder {
                    color: #aaa;
                }

                &.short {
                    width: 40px;
                }

                &.long {
                    width: 300px;
                }

                &.label {
                    display: inline-block;
                    margin-right: 6px;
                    width: 120px;
                }

                &.specialization {
                    margin-left: 4px;
                    display: block;
                    width: 140px;
                }
            }

            select {
                display: inline-block;
                margin-right: 6px;
                width: 120px;
            }
        }
    }

    .std-padding {
        padding: 8px !important;
    }

    .std-padding-top {
        padding-top: 8px !important;
    }

    .std-padding-top2 {
        padding-top: 16px !important;
    }


</style>