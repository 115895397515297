<template>
    <div class="third-column">
        <h4>Backgrounds</h4>
        <span class="add-row" v-on:click="addBackground()">+ Add a background</span>
        <div v-for="(bg, i) in backgrounds" :key="i" class="input-wrap specialization-wrap">
            <select v-model="bg[0]" placeholder="Background name">
                <option></option>
                <option v-for="(bgname,k) in backgroundsAvailable" :key="'bgname-'+k" :value="bgname">{{ bgname }}</option>
            </select>
            <dots :level.sync="bg[1]" :minimum="0" :maximum="5" />
            <span class="close" v-on:click="deleteRow(i)"><i class="fa fa-trash"></i></span>
            <input type="text" class="specialization" v-model="bg[2]" placeholder="Details" />
        </div>

        <div class="points-summary" :class="{'available' : Math.max(5 - backgroundPoints, 0) > 0 }">
            <div class="points">
                <span class="label">Points remain:</span>
                <span class="number">{{ Math.max(5 - backgroundPoints, 0) }}</span>
            </div>
            <div class="freebies" v-show="Math.max(backgroundPoints - 5, 0) > 0">
                <span class="label">Freebies used:</span>
                <span class="number">{{ Math.max(backgroundPoints - 5, 0) }}</span>
            </div>
            <div class="warning"><i class="fa fa-exclamation-circle"></i></div>
        </div>
    </div>
</template>

<script>

    import Dots from './parts/Dots'

    export default({
        name: 'SheetBackgrounds',
        components: {
            Dots
        },
        data() {
            return {
                vampire: false,
                backgrounds: [],
                backgroundsAvailable: [
                    'Allies',
                    'Alternate Identity',
                    'Armory',
                    'Clan Prestige',
                    'Contacts',
                    'Domain',
                    'Domitor',
                    'Fame',
                    'Generation',
                    'Haven - Size',
                    'Haven - Security',
                    'Herd',
                    'Influence',
                    'Mentor',
                    'Resources',
                    'Status',
                    'Other'
                ]
            }
        },
        props: [
            'sheetBackgrounds',
            'sheetVampire',
            'sheetFreebies'
        ],
        computed: {
            backgroundPoints: function() {

                if (this.backgrounds && this.backgrounds.length > 0) {
                    let totalResources = this.backgrounds
                        .filter((v) => {
                            return v[0] === 'Resources';
                        }).reduce((a, b) => a + b[1], 0);


                    let totalHaven = this.backgrounds
                        .filter((v) => {
                            return v[0] === 'Haven - Size' || v[0] === 'Haven - Security';
                        }).reduce((a, b) => a + b[1], 0);

                    return this.backgrounds.reduce((a, b) => a + b[1], 0)- Math.min(totalHaven, totalResources)
                }
                return 0;

            }
        },
        methods: {
            addBackground: function() {
                this.backgrounds.push(['',0,'']);
            },
            deleteRow: function(i) {
                this.backgrounds = this.backgrounds.filter(function(v,k) { return k !== i; });
            }
        },
        watch: {
            sheetBackgrounds: {
                handler: function() {
                    this.backgrounds = this.sheetBackgrounds;
                },
                deep: true
            },
            sheetVampire: function() {
                  this.vampire = this.sheetVampire;
            },
            backgrounds: {
                handler: function() {
                    this.$emit('update:sheetBackgrounds', this.backgrounds);
                    this.$emit('update:sheetFreebies', Math.max(this.backgroundPoints - 5, 0));
                },
                deep: true
            }
        }
    });
</script>