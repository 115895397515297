<template>
    <div class="third-column">
        <h4>Equipment</h4>
        <span class="add-row" v-on:click="addRow()">+ Add an item</span>

        <div v-for="(equip,i) in equipment" :key="'equipment-' + i" class="input-wrap equipment-row">
            <span class="number">{{ i + 1}}.</span>
            <input type="text" v-model="equipment[i]" placeholder="Equipment" />
            <span class="close" v-on:click="deleteRow(i)"><i class="fa fa-trash"></i></span>
        </div>

        <span class="sub">Please put each item on a new line.</span>
    </div>
</template>

<script>
    export default({
        name: 'SheetEquipment',
        props: [
            'sheetEquipment'
        ],
        data() {
            return {
                equipment: []
            }
        },
        methods: {
            addRow: function() {
                if (this.equipment.length < 100) {
                    this.equipment.push('');
                }
            },
            deleteRow: function(i) {
                this.equipment = this.equipment.filter(function(v,k) {
                    return k !== i;
                });
            }
        },
        mounted() {
            this.equipment = this.sheetEquipment;
        }
    });
</script>


<style lang="scss">
    .equipment-row {
        display: flex;
        span.number {
            display: inline-block;
            width: 24px;
            font-size: 0.8em;
            vertical-align: bottom;
            line-height: 24px;
            text-align: right;
            padding-right: 6px;
        }
        input {
            display: block;
            flex: 1 1 auto;
        }
        span.close {
            display: inline-block;
            padding-left: 12px;
        }
    }
</style>